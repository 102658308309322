body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }
  
/* FAQs Section */
.faq-list {
    margin: 20px 0;
    padding: 0;
    list-style: none;
  }
  
  .faq-item {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .faq-item h2 {
    margin: 0 0 10px;
    font-size: 1.5em;
    color: #333;
  }
  
  .faq-item p {
    margin: 0;
    font-size: 1em;
    color: #666;
  }