body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}
.App {
  text-align: left;
  padding: 15px;
}
.app-icon {
  width: 40px; /* Adjust the size of the app icon */
  height: auto;
  margin-right: 20px; /* Add some space between the icon and the menu */
  border-radius: 10px; /* Add some border radius to the icon */
  border: 1px solid white; /* Add a small white border to the icon */
  box-shadow: 0 4px 8px rgba(121, 121, 121, 0.2); /* Add shadow to the icon */
}

.header-content {
  display: flex; /* Align children horizontally */
  align-items: center; /* Align children vertically in the center */
  justify-content: space-between; /* Space out children */
  flex-grow: 1; /* Allow the nav to take up remaining space */
}
.header-content .header-links{
  display: none;
}

.App-header {
  background-color: #282c34;
  padding: 5px;
  padding-left: 20px;
  color: white;
  position: sticky; /* Make the header sticky */
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0; /* Stick to the top of the viewport */
  z-index: 1000; /* Ensure the header is above other content */
}

.App-header nav {
  display: flex;
  align-items: center; /* Align items vertically in the center */
 
  flex-grow: 1; /* Allow the nav to take up remaining space */
  
}

.App-header nav ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center; /* Ensure items are centered vertically */
  width: 100%; /* Make the ul take full width of nav */
  padding-inline-start: 1px;
  padding: 20px;
}

.App-header nav ul li {
  display: inline; /* Ensure each list item is displayed inline */
}

.App-header nav ul li a {
  color: white;
  text-decoration: none;
}
.App-header nav ul li {
  margin: 0 10px; /* Add horizontal margin to space out the nav items */
}
.mobile-icon {
  display: none;
}

.header-content {
  display: flex; /* Align children horizontally */
  align-items: center; /* Align children vertically in the center */
  justify-content: space-between; /* Space out children */
  
}


.hero-section {
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: left;
  position: relative;
  
  
}


.hero-section .overlay {
  background-color: #f16622;
  color: white;
  padding: 10px;
  text-align: center;
  margin: 20px;

}

.hero-section .buttons .btn {
  width: 150px;
  text-decoration: none;
  border-radius: 5px;
  padding: 15px;
}

.features-section {
  padding: 50px;
  text-align: center;
}

.feature {
  display: flex;
  align-items: center;
  
}

.feature.left .feature-image {
  order: 1;
  width: 40%; /* Make the image take full width */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 30px;
  border-radius: 10px;

}

.feature.right .feature-image {
  order: 2;
  width: 40%; /* Make the image take full width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px;
}

.feature.left .feature-text {
  order: 2;
  text-align: left;
  margin-bottom: 30px;
}

.feature.right .feature-text {
  order: 1;
  text-align: left;
  margin-bottom: 30px;
}

.feature-image {
  width: 250px;
  height: 250px;
  margin: 0 20px;
}

.App-footer {
  background-color: #282c34;
  color: white;
  padding: 20px;
  text-align: center;
}

.App-footer .footer-buttons{
  display: flex;
  justify-content: center;
  margin: 20px;
}

.App-footer .footer-buttons .footer-btn {
  margin: 0 10px;
}

.App-footer .social-links a {
  color: white;
  margin: 0 10px;
  text-decoration: none;
}

.App-footer .social-icon {
  font-size: 35px;
}


.features-divider {
  width: 10%;
  height: 2px;
  background-color: #ffb96f;
  margin-bottom: 40px auto;
}


.collaboration-section {
  padding: 50px;
  text-align: center;
}

.operators-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.operator-icon {
  width: 100px;
  height: auto;
  
  border-radius: 10px;
}

.review-section {
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
}

.review-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.review-image {
  width: 100%;
  height: auto;
  max-width: 100%;
}

/* Reveal Animation */
.reveal {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.reveal.active {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 768px) {
  .App-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 7vh;
  }
  .header-content {
    width: 100%; /* Ensure the header content takes full width */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow to the bottom of the header */
  }
  .header-content .header-links{
    display: flex ;
  }
  .App-header .mobile-icon {
    font-size: 30px; /* Increase the size of the icon on mobile */
    margin: 15px; /* Adjust the margin for mobile */
    display: block;
    margin-left: auto;
  }

  .hero-section {
    height: 50vh;
    background-size: cover;
    justify-content: left;
    text-align: center;
    background-repeat: no-repeat;
    background-position: 55% center;
  }

  .hero-section .overlay {
    
    margin: 10px;
    padding: 5px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .hero-section .buttons .btn {
    width: 100px;
    padding: 5px;
   
  }

  .menu-icon {
    display: none; /* Hide the menu icon by default */
    font-size: 30px;
    cursor: pointer;
  }
  
  .App-header nav {
    display: flex;    
  }
  
  .App-header nav ul {
    display: none; /* Hide the nav links by default on mobile devices */
    flex-direction: column;
    position: fixed; /* Fix the position of the drawer menu */
    top: 0;
    left: 0;
    height: 100%; /* Full height */
    width: 60%; /* Width of the drawer menu */
    background-color: white; /* Change the background color of the drawer menu */
    transform: translateX(-100%); /* Hide the drawer menu by default */
    overflow-y: auto; /* Enable vertical scrolling if content overflows */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5); /* Add shadow to the drawer menu */
    justify-content: flex-start; /* Align items to the top */
    align-items: flex-start; /* Align items to the left */
    opacity: 0; /* Start with the drawer menu hidden */
    transition: transform 0.6s ease, opacity 0.6s ease; /* Ensure the transition duration is consistent */
  }

  .App-header nav ul.open {
    display: flex; /* Show the nav links when the menu is open */
    transform: translateX(0); /* Slide in the drawer menu */
    opacity: 1; /* Fade in the drawer menu */
  }

  .App-header nav ul li {
    text-align: left; /* Align text to the left */
    padding: 10px 20px; /* Adjust padding for better alignment */
    opacity: 0; /* Start hidden */
    transform: translateX(-20px); /* Start slightly off-screen */
    animation: reveal 0.5s forwards; /* Apply the reveal animation */    
    padding: 20px;
    width: 100%; /* Ensure the list item takes full width */
    &:not(:last-child) {
      border-bottom: 1px solid #ffb96f; /* Add a line after each list item except the last one */
    }
  }

  .App-header nav ul li a {
    color: black;
    text-decoration: none;
    padding: 20px;
    font-size: 30px;
  }


.App-header nav ul.open li:nth-child(1) {
  animation-delay: 0.1s; /* Delay for the first item */
}

.App-header nav ul.open li:nth-child(2) {
  animation-delay: 0.2s; /* Delay for the second item */
}

.App-header nav ul.open li:nth-child(3) {
  animation-delay: 0.3s; /* Delay for the third item */
}

.App-header nav ul.open li:nth-child(4) {
  animation-delay: 0.4s; /* Delay for the fourth item */
}

.App-header nav ul.open li:nth-child(5) {
  animation-delay: 0.5s; /* Delay for the fifth item */
}

@keyframes reveal {
  to {
    opacity: 1; /* Fade in */
    transform: translateX(0); /* Move to the final position */
  }
}

  .features-section {
    padding: 10px;
    text-align: center;
  }
  .feature {
    flex-direction: column;
    text-align: center;
  }

  .feature.left .feature-image,
  .feature.right .feature-image {
    order: 1;
    margin: 0 auto;
    width: 100%; /* Make the image take full width */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 20px;
  }

  .feature.left .feature-text,
  .feature.right .feature-text {
    order: 2;
    text-align: center;
  }
  .features-divider {
    width: 40%;
    height: 2px;
    
    margin-bottom: 40px auto;
  }

  .operators-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .operator-icon {
    width: 80px;
    border-radius: 10px;
  }
}